.App {
  text-align: center;
  display: flex;
  max-width: 100vw;
  flex-direction: column;
}
.container{
  display: flex;
  max-width: 100vw;
  height: 100vh;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes moveUp {
  from {
    transform: translateY(0); 
  }
  to {
    transform: translateY(-100%); 
  }
}

.hideAnimation {
  animation: fadeOut 0.5s forwards;
}
.showAnimation{
  animation: fadeIn 0.5s forwards;
}
.move-up{
  animation: moveUp 1s forwards;
}

@media screen and (max-width: 900px) {
  .container{
    flex-direction: column;
  }
}