.mainContainer {
  width: 60rem;
  overflow: hidden;
  margin-top: 6rem;
}
.carousel {
  display: flex;
  transition: transform 0.5s ease;
}
.imageContainer {
  width: 28rem;
  height: 40rem;
  margin-right: 1rem;
  position: relative;
}
.image {
  height: inherit;
  width: inherit;
  object-fit: cover;
}
.empty {
  height: 40rem;
  margin-right: 1rem;
  padding: 0 6.25rem;
}
.focused {
  transition: transform 0.3s ease;
}
.overImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  transition: opacity 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: white;
  text-align: left;
  padding: 3rem 1rem;
}
.overImage strong {
  font-size: 3rem;
  margin-bottom: 3rem;
}
.overImage span {
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.overImage.hidden {
  opacity: 0;
}
.header {
  white-space: nowrap;
  position: absolute;
  top: 115px;
  color: white;
  text-align: left;
  z-index: 10;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
}
.header1{
  left: 220px;
}
.header2 {
  left: 680px;
}
.header strong {
  font-size: 2rem;
}
.header span {
  font-size: 1.5rem;
  font-weight: bold;
}
.header2 strong {
  font-size: 2rem;
}
.header2 span {
  font-size: 1.5rem;
  font-weight: bold;
}

@media screen and (max-width: 1024px) {
  .mainContainer{
    width: 40rem;
  }
  .empty{
    padding: 0 3.125rem;
  }
  .imageContainer{
    width: 18rem;
    height: 26rem;
  }
}