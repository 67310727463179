.event {
 margin: 1.5rem;
}

.event_label {
  width: 20rem;
  height: 4rem;
  position: relative;
  display: block;
  background: #242424;
  border-radius: 2rem;
  cursor: pointer;
  transition: 0.3s;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  padding: 1.3rem 2.4rem;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
}
.event_label:after {
  content: "";
  width: 10rem;
  position: absolute;
  top: 0px;
  bottom: 0;
  right: 0;
  background: #414141;
  border-radius: 2rem;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  z-index: -1;
}
.event_input {
  width: 0;
  height: 0;
  visibility: hidden;
}
.event_input:checked + .event_label:after {
  right: 0;
  transform: translateX(-100%);
}
.events{
  margin-left: 0.8rem;
}