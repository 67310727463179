@keyframes scrollUpDown {
  0% {
    transform: translateY(0);
  }
  1% {
    transform: translateY(-8rem);
  }
  70% {
    transform: translateY(-17rem);
  }
  71% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

.mainContainer {
  position: absolute;
  top: 0;
  right: 0;
  writing-mode: vertical-lr;
  text-align: left;
  background-color: rgb(179, 154, 255);
  font-size: 2rem;
  font-weight: 600;
  overflow-y: auto;
  white-space: nowrap;
  padding: 0.8rem;
  z-index: 100;
  height: 100%;
}
.textContainer {
  animation: scrollUpDown 2s infinite;
}
.textContainer strong {
  font-size: 4rem;
}

@media screen and (max-width: 900px) {
  .mainContainer {
    display: none;
  }
}